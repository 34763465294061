<template>
  <div class="">
    <!-- list of tabs-->
    <div class="flex justify-between">
      <div class="flex justify-left">
        <div>
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
              id="tabs"
              name="tabs"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-Primary-400 focus:outline-none focus:ring-Primary-400 sm:text-sm"
            >
              <option
                v-for="tab in tabs"
                :key="tab"
                :selected="activeTab === tab"
              >
                {{ tab }}
              </option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <button
                  v-for="tab in tabs"
                  :key="tab"
                  @click="activeTab = tab"
                  :class="[
                    activeTab === tab
                      ? 'border-Primary-400 text-Primary-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                  ]"
                  :aria-current="activeTab === tab ? 'page' : undefined"
                >
                  {{ tab }}
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          
          <div class="relative flex items-center mt-2">
            <input
              type="text"
              name="search"
              id="search"
              ref="searchInput"
              v-model="searchQuery"
              placeholder="Search all entities"
              class="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-Primary-600 sm:text-sm sm:leading-6"
            />
            <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <kbd
                class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400"
                >⌘K</kbd
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- selected tabs -->
    <div class="flex-1 overflow-y-auto mt-4">
      <div v-show="activeTab === 'donors'" class="donors-container">
        <!-- Display donors array here -->
        <ul class="list-container">
          <li class="capitalize" v-for="donorName in sortedDonors" :key="donorName">
            <nuxt-link :to="`/bydonors/${donorName.iso}`">{{
              donorName._id
            }}</nuxt-link>
          </li>
        </ul>
      </div>
      <div v-show="activeTab === 'recipients'" class="recipients-container">
        <!-- Display recipients array here -->
        <ul class="list-container">
          <li class="capitalize" v-for="recipientName in sortedRecipients" :key="recipientName">
            <nuxt-link :to="`/recipients/${recipientName._code}`">{{
              recipientName._id
            }}</nuxt-link>
          </li>
        </ul>
      </div>

      <div v-show="activeTab === 'search'" class="search-container min-h-[300px]" >
        <h2 class="text-2xl">Search Results</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <h3 class="text-xl font-semibold ">Donors</h3>
            <ul>
              <li class="capitalize"
                v-for="categoryName in searchResultsAllTypes[0]"
                :key="categoryName._id"
              >
                <nuxt-link :to="`/bydonors/${categoryName._id}`">{{
                  categoryName._id
                }}</nuxt-link>
              </li>
            </ul>
          </div>

          <div>
            <h3 class="text-xl font-semibold ">Recipients</h3>
            <ul>
              <li class="capitalize"
                v-for="categoryName in searchResultsAllTypes[1]"
                :key="categoryName._id"
              >
                <nuxt-link :to="`/recipients/${categoryName._code}`">{{
                  categoryName._id
                }}</nuxt-link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  donors: {
    type: Array,
    required: true,
  },
  recipients: {
    type: Array,
    required: true,
  },
  categories: {
    type: Array,
    required: true,
  },
});
const tabs = ["donors", "recipients"];

/** sorted version of props */
const sortedDonors = ref([]);
const sortedRecipients = ref([]);
const sortedCategories = ref([]);

function sortAllProps() {
  sortedDonors.value = props.donors.sort((a, b) => a._id.localeCompare(b._id));
  sortedRecipients.value = props.recipients.sort((a, b) =>
    a._id.localeCompare(b._id)
  );
  sortedCategories.value = props.categories.sort((a, b) =>
    a._id.localeCompare(b._id)
  );
}


sortAllProps();


const searchQuery = ref("");

watch(searchQuery, (searchQuerynew) => {
  if (searchQuerynew.length > 1) {
    activeTab.value = "search";
  } else {
    activeTab.value = "donors";
  }
});

const searchResultsAllTypes = computed(() => {
  if (searchQuery.value.length < 2) return [[], [], []];
  return [
    sortedDonors.value.filter((donor) =>
      donor._id.toLowerCase().includes(searchQuery.value.toLowerCase())
    ),
    sortedRecipients.value.filter((recipient) =>
      recipient._id.toLowerCase().includes(searchQuery.value.toLowerCase())
    ),
    sortedCategories.value.filter((category) =>
      category._id.toLowerCase().includes(searchQuery.value.toLowerCase())
    ),
  ];
});

const activeTab = ref("donors");

/** search input */
const keys = useMagicKeys()
const MetaK = keys['Meta+k']
const searchInput = ref(null)
watch(MetaK, (v) => {
  if (v)
    searchInput.value.focus()
})
</script>

<style lang="scss" scoped>
.list-container {
  column-count: 1;

  column-gap: 1em;
  & > div {
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5em;
  }

  ul li {
    display: list-item;
    padding-left: 2em;
    text-indent: -0.5em;
    a {
      text-decoration: none;
      color: #888;
      &:hover {
        color: #004fa2;
        /* color: orange; */
      }
    }
  }
}

.list-container {
  ul li:first-of-type {
    color: #004fa2;
    /* color: orange; */
    float: left;
    padding-left: 0;
    padding-top: 1px;
    text-indent: 0;
    font-family: "nowaymedium", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .list-container {
    column-count: 3;
  }
}

@media only screen and (min-width: 960px) {
  .list-container {
    column-count: 4;
  }
}
</style>
