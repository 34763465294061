<template>
  <div>
    <NavigationHeader />
    <TooltipGlobal> </TooltipGlobal>
    <TooltipGlobalCharts> </TooltipGlobalCharts>
    <NuxtLoadingIndicator :height="8" color="#fff" />
    <slot />
    <div class="bg-white pb-10" v-if="route.name !== 'index'">
      <div class="fluid-container-custom">
        <h3 class="text-xl mt-8 pt-8 pb-2">Explore donors and recipients</h3>
        <navigationBrowserComponent
          :donors="store.allDonorNames"
          :recipients="
            store.allRecipientNames.filter((country) => country._code !== 'NA')
          "
          :categories="store.allCategories"
        >
        </navigationBrowserComponent>
      </div>
    </div>
    <NavigationFooter />
  </div>
</template>
<script setup>
import { useBaseDataStore } from "@/stores/baseData.js";
const store = useBaseDataStore();
import { useRoute } from "vue-router";
const route = useRoute();
// let fetchingKeyInError= false
// /** eerror capture for this component */
onErrorCaptured((err) => {
  console.log("error capturado");
  console.log(err.message);
  console.log(err.stack);

  return false;
});

onMounted(() => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-BHNNDW48HC");
  }
});
</script>
