<script setup lang="ts">

</script>

<template>
  <footer class="bg-Primary-800 py-10 px-6">
    <div class="flex flex-col lg:flex-row justify-between items-start text-white max-w-screen-2xl mx-auto">
      <div>
        <img 
          src="/epd_logo_monochromo_white.png"
          class="h-[5rem]"
        />
        <p class="mt-4">© 2023 European Democracy Hub.</p>
      </div>
      <div class="mt-12 lg:mt-0">
        <ul>
          <li>Rue Froissart 123-133</li>
          <li class="mt-2">B-1040 Brussels</li>
          <li class="mt-2">
            <a
              class="hover:text-Primary-200" 
              href="mailto:info@europeandemocracyhub.epd.eu"
            >info@europeandemocracyhub.epd.eu</a>
          </li>
          <li class="mt-2">
            <a 
              class="hover:text-Primary-200" 
              href="tel:3227332282"
            >+32 2 733 2282</a>
          </li>
        </ul>
      </div>
      <div class="mt-12 lg:mt-0">
        <ul>
          <li class="hover:text-Primary-200">
            <a href="https://europeandemocracyhub.epd.eu/privacy-policy">Privacy Policy</a>
          </li>
          <li class="mt-2 hover:text-Primary-200">
            <a href="#">Cookie Preferences</a>
          </li>
          <li class="mt-2 hover:text-Primary-200">
            <a href="https://europeandemocracyhub.epd.eu/contact">Contact</a>
          </li>
        </ul>
      </div>
      <div class="mt-12 lg:mt-0">
        <ul class="flex items-center">
          <li class="lg:ml-6">
            <a href="https://twitter.com/eudemocracyhub">
              <IconsTwitter class="hover:scale-110" />
            </a>
          </li>
          <li class="ml-6">
            <a href="https://www.facebook.com/epdemocracy">
              <IconsFacebook class="hover:scale-110" />
            </a>
          </li>
          <li class="ml-6">
            <a href="https://www.linkedin.com/company/european-partnership-for-democracy">
              <IconsLinkdin class="hover:scale-110" />
            </a>
          </li>
          <li class="ml-6">
            <a href="https://www.youtube.com">
              <IconsYoutube class="hover:scale-110" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
