<template>
  <div
    class="absolute z-50 w-fit shadow-xl  pointer-events-none border-Primary-200 border rounded-md bg-white"
    v-show="show"
    :style="{
      top: getPositionTop(),
      left: positionLeft,
    }"
  >
    <div      
      ref="innertooltip"
      class="inner-tooltip"
      :style="{ 'max-width': getMaxWidth() }"
    >
      <div
        class="p-3 text-xs  "
        id="teleport-tooltipchart"
      ></div>
    </div>
  </div>
</template>

<script setup>
const tooltip = useTooltip();
const tooltipPosition= tooltip.tooltipPosition

const props = defineProps({
  size: {
    type: String,
    default: "medium",
  },
});

const top = computed(() => {
 return tooltipPosition.value.y;
});
const left = computed(() => {
  return tooltipPosition.value.x;
});

const show = computed(() => {
  return tooltipPosition.value.show && tooltipPosition.value.type === '4chart';
});


const positionLeft = ref(0);

const setPositionLeft = () => {
  if (innertooltip.value === undefined) return left.value;
  const ww = window.innerWidth;
  const tooltipRealSize = innertooltip.value.offsetWidth;
  if (left.value - tooltipRealSize < 0) return "10px"; // left overflow
  if (left.value + tooltipRealSize > ww) {
    return left.value - tooltipRealSize + "px";
  } else return left.value - tooltipRealSize + "px";
};

const getPositionTop = () => {
  return top.value + 12 + "px";
};

const getMaxWidth = () => {
  if (props.size === "small") return "200px";
  if (props.size === "medium") return "300px";
  if (props.size === "large") return "500px";
};

watch(show, (newValue, oldValue) => {
  nextTick(() => {    
    positionLeft.value = setPositionLeft();
  });
});

const innertooltip = ref(null);

</script>

<style lang="css" scoped>
/* vue trantsition opacity */

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter, .v-leave-to /* .v-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
